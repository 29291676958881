@import url(https://fonts.googleapis.com/css?family=Noto+Sans+SC);
.App {
  text-align: center;
  min-height: 1000px;
  min-width: 250px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiAutocomplete-listbox li {
  min-height: 25px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ql-container {
  min-height: 200px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.threadCard_root__A1yUd {
  text-align: left;
  cursor: pointer;
  max-height: 160px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: None;
  border-bottom: 1px solid lightgrey;
}

.threadCard_title__pgSCN {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.threadCard_moreInfoRight__3snOZ {
  font-size: 0.725rem;
  padding-right: 10px;
  text-align: right;
  padding-top: 4px;
}

.threadCard_moreInfo__NGZFn {
  font-size: 0.725rem;
  padding-left: 4px;
  padding-top: 4px;
}

.threadCard_outlineIcon__3GRDC {
  width: 0.7em;
  color: grey;
  padding-left: 8px;
}

.threadCard_chipRoot__1MgUR {
  display: flex;
  overflow-x: scroll;
}

.threadCard_content__29H68 {
  padding: 8px;
  padding-bottom: 0px;
}

.threadCard_categoryText__CJ_eD {
  font-size: 0.8rem;
  margin-bottom: 4px;
}

.threadCard_action__2GcSW {
  padding: 0px;
  flex: 1 1;
}

body {
  margin: 0;
  font-family: "Noto Sans SC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTableCell-root.MuiTableCell-paddingCheckbox {
  display: none;
}

.rich-text > img {
  max-width: 100%;
}

p {
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.MuiTableContainer-root {
  background-image: url(https://s3.amazonaws.com/duibiao.static/images/logo/site_name_transparent_sm.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}

.duibiao-watermark {
  background-image: url(https://s3.amazonaws.com/duibiao.static/images/logo/site_name_transparent_sm.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}
canvas {
  background-image: url(https://s3.amazonaws.com/duibiao.static/images/logo/site_name_transparent_sm.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}

