.root {
  text-align: left;
  cursor: pointer;
  max-height: 160px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: None;
  border-bottom: 1px solid lightgrey;
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.moreInfoRight {
  font-size: 0.725rem;
  padding-right: 10px;
  text-align: right;
  padding-top: 4px;
}

.moreInfo {
  font-size: 0.725rem;
  padding-left: 4px;
  padding-top: 4px;
}

.outlineIcon {
  width: 0.7em;
  color: grey;
  padding-left: 8px;
}

.chipRoot {
  display: flex;
  overflow-x: scroll;
}

.content {
  padding: 8px;
  padding-bottom: 0px;
}

.categoryText {
  font-size: 0.8rem;
  margin-bottom: 4px;
}

.action {
  padding: 0px;
  flex: 1;
}
