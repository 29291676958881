body {
  margin: 0;
  font-family: "Noto Sans SC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTableCell-root.MuiTableCell-paddingCheckbox {
  display: none;
}

.rich-text > img {
  max-width: 100%;
}

p {
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.MuiTableContainer-root {
  background-image: url(https://s3.amazonaws.com/duibiao.static/images/logo/site_name_transparent_sm.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}

.duibiao-watermark {
  background-image: url(https://s3.amazonaws.com/duibiao.static/images/logo/site_name_transparent_sm.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}
canvas {
  background-image: url(https://s3.amazonaws.com/duibiao.static/images/logo/site_name_transparent_sm.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}
